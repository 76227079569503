// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-company-tsx": () => import("./../src/pages/about/company.tsx" /* webpackChunkName: "component---src-pages-about-company-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-generic-tsx": () => import("./../src/pages/generic.tsx" /* webpackChunkName: "component---src-pages-generic-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kakaritsuke-tsx": () => import("./../src/pages/kakaritsuke.tsx" /* webpackChunkName: "component---src-pages-kakaritsuke-tsx" */),
  "component---src-pages-note-tsx": () => import("./../src/pages/note.tsx" /* webpackChunkName: "component---src-pages-note-tsx" */),
  "component---src-pages-recruit-entry-tsx": () => import("./../src/pages/recruit/entry.tsx" /* webpackChunkName: "component---src-pages-recruit-entry-tsx" */),
  "component---src-pages-recruit-index-tsx": () => import("./../src/pages/recruit/index.tsx" /* webpackChunkName: "component---src-pages-recruit-index-tsx" */),
  "component---src-pages-store-tsx": () => import("./../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */)
}

